import { Box, Flex } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'react-native';

import { Typography } from '../../../../components';
import { SelectWithSearchHookForm } from '../../../../components/SelectWithSearch/SelectWithSearchHookForm';
import { DATA_REQUEST_LIMIT } from '../../../../constants';
import { useDebounce, useGetAlloCategories } from '../../../../hooks';
import { transformSelectOptionsWithInfo } from '../../../../utils';
import { styles } from './styles';

const AlloCategory = () => {
  const { watch, setValue } = useFormContext();
  const [path, setPath] = useState('');

  const valueProperty = 'id';
  const labelProperty = 'name';
  const initParentName = watch('alloProduct.categoryName');
  const categoryId = watch('alloProduct.categoryId');

  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } = useDebounce(searchValue);

  const [lazyQueryRequest, { loading, fetchMore }] = useGetAlloCategories({
    fetchPolicy: 'cache-first',
  });

  const fetchMoreData = async () => {
    const { data } = await fetchMore({
      variables: {
        params: {
          search: debouncedSearchValue,
          offset: options?.length,
          limit: DATA_REQUEST_LIMIT,
        },
      },
    });
    const result = data.getAlloCategories;

    setOptions((prevState) => {
      return [...prevState, ...result];
    });
  };

  const fetchQueryResponse = async () => {
    await lazyQueryRequest({
      variables: {
        params: {
          search: debouncedSearchValue,
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
        },
      },
      onCompleted: (response) => {
        const result = response?.getAlloCategories;
        setOptions((prevState) => {
          return [...prevState, ...result];
        });
      },
    });
  };

  useEffect(() => {
    if (debouncedSearchValue)
      lazyQueryRequest({
        variables: {
          params: {
            search: debouncedSearchValue,
            offset: 0,
            limit: DATA_REQUEST_LIMIT,
          },
        },
        onCompleted: (response) => {
          const result = response?.getAlloCategories;
          setOptions(result);
        },
      });
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (initParentName) {
      lazyQueryRequest({
        variables: {
          params: {
            search: initParentName,
            offset: 0,
            limit: DATA_REQUEST_LIMIT,
          },
        },
        onCompleted: (response) => {
          const result = response?.getAlloCategories;
          setOptions((prevState) => {
            return [...prevState, ...result];
          });

          const initPathName = result.find((res) => res.id === categoryId).path;
          setPath(initPathName);
        },
      });
    }
  }, []);

  const onChangeCategorySelect = (option) => {
    if (!option) {
      setValue('alloProduct.categoryName', '');
      setValue('alloProduct.categoryId', null);
      setPath('');
      return;
    }
    setValue('alloProduct.categoryName', option?.label);
    setPath(option?.info);
  };

  return (
    <>
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.category' variant='heading' />
      </Flex>
      <Box style={styles.wrapper}>
        <SelectWithSearchHookForm
          width={'100%'}
          label='app.category'
          onMenuOpen={fetchQueryResponse}
          onMenuScrollToBottom={fetchMoreData}
          onChange={onChangeCategorySelect}
          placeholderIntlId='app.enterValue'
          setSearchValue={setSearchValue}
          options={transformSelectOptionsWithInfo({
            data: options,
            value: valueProperty,
            label: labelProperty,
            info: 'path',
          })}
          isLoading={loading || isDebouncing}
          name={`alloProduct.categoryId`}
          mb={0}
          pr={0}
          pl={0}
          isValChange
        />
        {path ? <Text>{path}</Text> : null}
      </Box>
    </>
  );
};

export default AlloCategory;
